import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VCard,
        {
          staticClass: "mt-2 mb-3",
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.botaoFiltrar.apply(null, arguments)
            }
          }
        },
        [
          _c(
            VRow,
            { staticClass: "mx-6 mt-3 pt-3", attrs: { align: "center" } },
            [
              _c(
                VCol,
                {
                  attrs: { cols: "12", xs: "12", sm: "12", md: "12", lg: "8" }
                },
                [
                  _c(
                    VCardText,
                    {
                      staticClass: "text-left font-weight-black display-1 pl-0",
                      style: { color: _vm.primaryColorFont }
                    },
                    [
                      _c(
                        VIcon,
                        { staticClass: "ma-2", attrs: { large: "" } },
                        [_vm._v(" mdi-progress-close ")]
                      ),
                      _vm._v(_vm._s(_vm.title) + " ")
                    ],
                    1
                  ),
                  _c(VTextarea, {
                    attrs: {
                      solo: "",
                      small: "",
                      label: _vm.textAreaLabel,
                      hint: _vm.textAreaHint,
                      placeholder: _vm.textAreaPlaceholder,
                      clearable: ""
                    },
                    model: {
                      value: _vm.filtros.ds_nosso_numero,
                      callback: function($$v) {
                        _vm.$set(_vm.filtros, "ds_nosso_numero", $$v)
                      },
                      expression: "filtros.ds_nosso_numero"
                    }
                  }),
                  _c(
                    VCardText,
                    { staticClass: "font-weight-light text-left title-1" },
                    [_vm._v(" " + _vm._s(_vm.dica) + " ")]
                  )
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "mt-2",
                  attrs: { cols: "12", sm: "12", md: "12", lg: "4" }
                },
                [
                  _c(
                    VCol,
                    { attrs: { cols: "12", sm: "12", md: "12", lg: "12" } },
                    [
                      _c(
                        VCard,
                        { staticClass: "grey white--text" },
                        [
                          _c(
                            VIcon,
                            { staticClass: "ma-2 text-left white--text" },
                            [_vm._v(" mdi-chevron-down ")]
                          ),
                          _vm._v("fazer upload de arquivo XLS ")
                        ],
                        1
                      ),
                      _c(
                        VCardText,
                        { staticClass: "font-weight-light text-left title-1" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.instrucaoUpload) +
                              ". Formatos compatíveis: .xls/.xlsx/.xlsm Limite de tamanho: 60mb "
                          )
                        ]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "grey white--text",
                          attrs: {
                            block: "",
                            plain: "",
                            small: "",
                            href: _vm.link_modelo
                          }
                        },
                        [
                          _c(VIcon, { staticClass: "ma-2 text-left" }, [
                            _vm._v(" mdi-file-download ")
                          ]),
                          _vm._v("Baixar modelo ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "mt-2", attrs: { cols: "12", sm: "12" } },
                    [
                      _c(VFileInput, {
                        key: _vm.counterRender,
                        ref: "myFileInput",
                        staticClass: "mb-n7 white--text",
                        attrs: {
                          accept:
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .xlsm",
                          label: "Selecione o arquivo (.xls/.xlsx/.xlsm)",
                          "show-size": "",
                          chips: "",
                          solo: "",
                          id: "fileInput",
                          name: "file"
                        },
                        on: { change: _vm.uploadFileReference }
                      }),
                      _vm.fazendoUpload
                        ? _c(
                            VCardText,
                            {
                              staticClass: "font-weight-light text-left title-1"
                            },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "ma-1",
                                  attrs: {
                                    color:
                                      _vm.retornaDadosXls() > 0
                                        ? "green"
                                        : "red",
                                    "x-small": "",
                                    elevation: "0",
                                    fab: ""
                                  }
                                },
                                [
                                  _c(VIcon, { staticClass: "white--text" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.retornaDadosXls() > 0
                                            ? "mdi-check"
                                            : "mdi-close"
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._v(
                                " Títulos verificados: " +
                                  _vm._s(_vm.retornaDadosXls()) +
                                  " linhas"
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.fazendoUpload
                        ? _c(
                            VCardText,
                            { staticClass: "text-left title-1" },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "ma-1",
                                  attrs: {
                                    color: "green",
                                    "x-small": "",
                                    elevation: "0",
                                    fab: ""
                                  }
                                },
                                [
                                  _c(VIcon, { staticClass: "white--text" }, [
                                    _vm._v("mdi-magnify")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" Para confirmar, clique em Filtrar ")
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCol,
                [
                  _c(
                    VExpansionPanels,
                    [
                      _c(
                        VExpansionPanel,
                        [
                          _c(
                            VExpansionPanelHeader,
                            {
                              staticClass: "grey lighten-4",
                              attrs: { small: "" }
                            },
                            [
                              _c(
                                VCardText,
                                {
                                  staticClass: "font-weight-light black--text"
                                },
                                [
                                  _c(
                                    VIcon,
                                    { staticClass: "primary--text lighten-2" },
                                    [_vm._v(" search ")]
                                  ),
                                  _vm._v(" Filtrar Resultados ")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            VExpansionPanelContent,
                            [
                              _c(
                                VRow,
                                { staticClass: "my-3" },
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "d-flex flex-row",
                                      attrs: {
                                        cols: "4",
                                        xs: "2",
                                        sm: "2",
                                        md: "3",
                                        lg: "3"
                                      }
                                    },
                                    [
                                      _c(VTextField, {
                                        directives: [
                                          {
                                            name: "maska",
                                            rawName: "v-maska",
                                            value: [
                                              "###.###.###-##",
                                              "##.###.###/####-##"
                                            ],
                                            expression:
                                              "[\n                                            '###.###.###-##',\n                                            '##.###.###/####-##'\n                                        ]"
                                          }
                                        ],
                                        attrs: {
                                          solo: "",
                                          small: "",
                                          label: "CPF/CNPJ",
                                          hint: "Informe o Número do CPF/CNPJ",
                                          placeholder: "Ex.:000.000.000-00",
                                          clearable: ""
                                        },
                                        model: {
                                          value:
                                            _vm.filtros
                                              .nu_identificacao_devedor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filtros,
                                              "nu_identificacao_devedor",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                                            filtros.nu_identificacao_devedor\n                                        "
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      attrs: {
                                        cols: "5",
                                        xs: "2",
                                        sm: "2",
                                        md: "3",
                                        lg: "3"
                                      }
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          solo: "",
                                          small: "",
                                          label: "Nome",
                                          hint:
                                            "Informe o nome para efetuar a busca",
                                          placeholder: "Ex.: José",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.filtros.nm_devedor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filtros,
                                              "nm_devedor",
                                              $$v
                                            )
                                          },
                                          expression: "filtros.nm_devedor"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      attrs: {
                                        cols: "10",
                                        xs: "2",
                                        sm: "2",
                                        md: "3",
                                        lg: "3"
                                      }
                                    },
                                    [
                                      _c(VSelect, {
                                        attrs: {
                                          solo: "",
                                          small: "",
                                          items: _vm.listaUfs,
                                          label: "UF",
                                          "return-object": true
                                        },
                                        model: {
                                          value: _vm.filtros.cd_uf_devedor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filtros,
                                              "cd_uf_devedor",
                                              $$v
                                            )
                                          },
                                          expression: "filtros.cd_uf_devedor"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      attrs: {
                                        cols: "4",
                                        xs: "2",
                                        sm: "2",
                                        md: "3",
                                        lg: "3"
                                      }
                                    },
                                    [
                                      _c(VCheckbox, {
                                        staticClass:
                                          "ml-6 mt-3 d-flex align-center justify-center",
                                        attrs: {
                                          color: "primary",
                                          label: "Renegociação"
                                        },
                                        model: {
                                          value: _vm.oferta,
                                          callback: function($$v) {
                                            _vm.oferta = $$v
                                          },
                                          expression: "oferta"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VRow,
                                { staticClass: "my-3" },
                                [
                                  !_vm.isUsuarioAgrupador
                                    ? _c(
                                        VCol,
                                        {
                                          attrs: {
                                            cols: "4",
                                            xs: "2",
                                            sm: "2",
                                            md: "3",
                                            lg: "3"
                                          }
                                        },
                                        [
                                          _c(VSelect, {
                                            attrs: {
                                              solo: "",
                                              dense: "",
                                              disabled:
                                                _vm.loadingAgrupador ||
                                                _vm.usuarioAgrupador != null,
                                              loading: _vm.loadingAgrupador,
                                              items: _vm.agrupadores,
                                              label: "Agrupador",
                                              "item-text": "nm_agrupador",
                                              "item-value": "ds_agrupador",
                                              "data-cy": "agrupador"
                                            },
                                            model: {
                                              value: _vm.filtros.ds_agrupador,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filtros,
                                                  "ds_agrupador",
                                                  $$v
                                                )
                                              },
                                              expression: "filtros.ds_agrupador"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VRow,
            { staticClass: "mx-0 mt-2 mb-0 pa-0" },
            [
              _c(VSpacer),
              _c(
                VCol,
                {
                  staticClass: "pr-9",
                  attrs: {
                    cols: "10",
                    xs: "6",
                    sm: "6",
                    md: "3",
                    lg: "3",
                    align: "center"
                  }
                },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "d-flex justify-center",
                      attrs: {
                        block: "",
                        color: "primary",
                        loading: _vm.loadingFiltros
                      },
                      on: { click: _vm.botaoFiltrar }
                    },
                    [
                      _vm._v(" Filtrar "),
                      _c(VIcon, { staticClass: "ml-2" }, [_vm._v("search")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: {
            "max-width": "800px",
            persistent: "",
            scrollable: "",
            color: "background",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialogError,
            callback: function($$v) {
              _vm.dialogError = $$v
            },
            expression: "dialogError"
          }
        },
        [
          _c(
            VCard,
            [
              _c(
                VToolbar,
                { attrs: { flat: "", dark: "", color: "error" } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogError = false
                        }
                      }
                    },
                    [_c(VIcon, [_vm._v("mdi-close")])],
                    1
                  ),
                  _c(VToolbarTitle, [_vm._v("Erro")])
                ],
                1
              ),
              _c(
                VCardText,
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        [
                          _c(
                            VCardText,
                            { staticClass: "font-weight-black" },
                            [
                              _c(VIcon, { staticClass: "ma-1" }, [
                                _vm._v("mdi-alert")
                              ]),
                              _vm._v(" Erro ao enviar a arquivo de upload ")
                            ],
                            1
                          ),
                          _c(VCardText, [
                            _vm._v(
                              " Houve um erro ao ler o arquivo de upload. Por favor, tente novamente. "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(VDivider),
              _c(
                VCardActions,
                { staticClass: "justify-end" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "ma-2",
                      attrs: { color: "error" },
                      on: {
                        click: function($event) {
                          _vm.dialogError = false
                        }
                      }
                    },
                    [_c(VIcon, [_vm._v("mdi-close")]), _vm._v("Fechar ")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }