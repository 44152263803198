<template>
    <div>
        <v-card @keyup.native.enter="botaoFiltrar" class="mt-2 mb-3">
            <v-row align="center" class="mx-6 mt-3 pt-3">
                <v-col cols="12" xs="12" sm="12" md="12" lg="8">
                    <v-card-text
                        v-bind:style="{ color: primaryColorFont }"
                        class="text-left font-weight-black display-1 pl-0"
                    >
                        <v-icon large class="ma-2"> mdi-progress-close </v-icon
                        >{{ title }}
                    </v-card-text>
                    <v-textarea
                        solo
                        small
                        :label="textAreaLabel"
                        :hint="textAreaHint"
                        :placeholder="textAreaPlaceholder"
                        clearable
                        v-model="filtros.ds_nosso_numero"
                    ></v-textarea>
                    <v-card-text class="font-weight-light text-left title-1">
                        {{ dica }}
                    </v-card-text>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="4" class="mt-2">
                    <v-col cols="12" sm="12" md="12" lg="12">
                        <v-card class="grey white--text">
                            <v-icon class="ma-2 text-left white--text">
                                mdi-chevron-down </v-icon
                            >fazer upload de arquivo XLS
                        </v-card>
                        <v-card-text class="font-weight-light text-left title-1">
                            {{ instrucaoUpload }}. Formatos compatíveis:
                            .xls/.xlsx/.xlsm Limite de tamanho: 60mb
                        </v-card-text>
                        <v-btn
                            block
                            plain
                            small
                            class="grey white--text"
                            :href="link_modelo"
                        >
                            <v-icon class="ma-2 text-left">
                                mdi-file-download </v-icon
                            >Baixar modelo
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="12" class="mt-2">
                        <v-file-input
                            class="mb-n7 white--text"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .xlsm"
                            label="Selecione o arquivo (.xls/.xlsx/.xlsm)"
                            show-size
                            chips
                            solo
                            ref="myFileInput"
                            id="fileInput"
                            name="file"
                            @change="uploadFileReference"
                            :key="counterRender"
                        ></v-file-input>
                        <v-card-text
                            v-if="fazendoUpload"
                            class="font-weight-light text-left title-1"
                        >
                            <v-btn
                                :color="
                                    retornaDadosXls() > 0 ? 'green' : 'red'
                                "
                                x-small
                                elevation="0"
                                fab
                                class="ma-1"
                            >
                                <v-icon class="white--text">
                                    {{
                                        retornaDadosXls() > 0
                                            ? 'mdi-check'
                                            : 'mdi-close'
                                    }}
                                </v-icon></v-btn
                            >
                            Títulos verificados:
                            {{ retornaDadosXls() }} linhas</v-card-text
                        >
                        <v-card-text
                            v-if="fazendoUpload"
                            class="text-left title-1"
                        >
                            <v-btn
                                color="green"
                                x-small
                                elevation="0"
                                fab
                                class="ma-1"
                                ><v-icon class="white--text"
                                    >mdi-magnify</v-icon
                                ></v-btn
                            >
                            Para confirmar, clique em Filtrar
                        </v-card-text>
                    </v-col>
                </v-col>
                <v-col>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header small class="grey lighten-4">
                                <v-card-text class="font-weight-light black--text">
                                    <v-icon class="primary--text lighten-2">
                                        search
                                    </v-icon>
                                    Filtrar Resultados
                                </v-card-text>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="my-3">
                                    <v-col
                                        cols="4"
                                        xs="2"
                                        sm="2"
                                        md="3"
                                        lg="3"
                                        class="d-flex flex-row"
                                    >
                                        <v-text-field
                                            solo
                                            small
                                            label="CPF/CNPJ"
                                            v-maska="[
                                                '###.###.###-##',
                                                '##.###.###/####-##'
                                            ]"
                                            hint="Informe o Número do CPF/CNPJ"
                                            placeholder="Ex.:000.000.000-00"
                                            v-model="
                                                filtros.nu_identificacao_devedor
                                            "
                                            clearable
                                        ></v-text-field>
                                    </v-col>

                                    <v-col
                                        cols="5"
                                        xs="2"
                                        sm="2"
                                        md="3"
                                        lg="3"
                                        class=""
                                    >
                                        <v-text-field
                                            solo
                                            small
                                            label="Nome"
                                            hint="Informe o nome para efetuar a busca"
                                            placeholder="Ex.: José"
                                            clearable
                                            v-model="filtros.nm_devedor"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="10" xs="2" sm="2" md="3" lg="3">
                                        <v-select
                                            solo
                                            small
                                            v-model="filtros.cd_uf_devedor"
                                            :items="listaUfs"
                                            label="UF"
                                            :return-object="true"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                                        <v-checkbox
                                            class="ml-6 mt-3 d-flex align-center justify-center"
                                            color="primary"
                                            v-model="oferta"
                                            label="Renegociação"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row class="my-3">
                                    <v-col
                                        v-if="!isUsuarioAgrupador"
                                        cols="4"
                                        xs="2"
                                        sm="2"
                                        md="3"
                                        lg="3"
                                    >
                                        <v-select
                                            solo
                                            dense
                                            :disabled="
                                                loadingAgrupador ||
                                                usuarioAgrupador != null
                                            "
                                            :loading="loadingAgrupador"
                                            :items="agrupadores"
                                            v-model="filtros.ds_agrupador"
                                            label="Agrupador"
                                            item-text="nm_agrupador"
                                            item-value="ds_agrupador"
                                            data-cy="agrupador"
                                        />
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>

            <v-row class="mx-0 mt-2 mb-0 pa-0">
                <v-spacer></v-spacer>
                <v-col
                    cols="10"
                    xs="6"
                    sm="6"
                    md="3"
                    lg="3"
                    class="pr-9"
                    align="center"
                >
                    <v-btn
                        block
                        class="d-flex justify-center"
                        color="primary"
                        :loading="loadingFiltros"
                        @click="botaoFiltrar"
                    >
                        Filtrar
                        <v-icon class="ml-2">search</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog
            v-model="dialogError"
            max-width="800px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar flat dark color="error">
                    <v-btn icon dark @click="dialogError = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Erro</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-card-text class="font-weight-black">
                                <v-icon class="ma-1">mdi-alert</v-icon>
                                Erro ao enviar a arquivo de upload
                            </v-card-text>
                            <v-card-text>
                                Houve um erro ao ler o arquivo de upload.
                                Por favor, tente novamente.
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end">
                    <v-btn
                        class="ma-2"
                        color="error"
                        @click="dialogError = false"
                    >
                        <v-icon>mdi-close</v-icon>Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import XLSX from 'xlsx';


export default {
    name: 'UploadEmMassa',
    props: {
        title: {
            type: String,
            required: true
        },
        textAreaLabel: {
            type: String,
            required: true
        },
        textAreaHint: {
            type: String,
            required: true
        },
        textAreaPlaceholder: {
            type: String,
            required: true
        },
        dica: {
            type: String,
            required: true
        },
        instrucaoUpload: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            counterRender: 1,
            dialogError: false,
            oferta: false,
            loadingInicial: true,
            loadingFiltros: false,
            customerCountRead: 0,
            primaryColorFont: '#757575',
            loadingAgrupador: false,
            link_modelo: '/Planilha-atualizacao-em-massa-Recuperi.xlsx',
            fazendoUpload: false,
            listaUfs: [
                'AC',
                'AL',
                'AP',
                'AM',
                'BA',
                'CE',
                'DF',
                'ES',
                'GO',
                'MA',
                'MT',
                'MS',
                'MG',
                'PA',
                'PB',
                'PR',
                'PE',
                'PI',
                'RJ',
                'RN',
                'RS',
                'RO',
                'RR',
                'SC',
                'SP',
                'SE',
                'TO'
            ],
            filtros: {
                cd_status: null,
                nm_devedor: null,
                cd_carteira: 'Todos',
                nu_identificacao_devedor: null,
                ds_nosso_numero: null,
                ds_numero_titulo: null,
                ds_agrupador: null,
                apontamento_nu_protocolo: null,
                dt_vencimento_range: [
                    //new Date(
                    //  new Date().setFullYear(new Date().getFullYear() - 1)
                    //)
                    //  .toISOString()
                    //.substr(0, 10),
                    //new Date().toISOString().substr(0, 10)
                ],
                dt_cadastro_range: [
                    //new Date(
                    //  new Date().setFullYear(new Date().getFullYear() - 1)
                    //)/
                    //  .toISOString()
                    //.substr(0, 10),
                    //new Date().toISOString().substr(0, 10)
                ],
                dt_atualizacao_range: [
                    //new Date(
                    //  new Date().setFullYear(new Date().getFullYear() - 1)
                    //)
                    //  .toISOString()
                    //.substr(0, 10),
                    //new Date().toISOString().substr(0, 10)
                ],
                dt_vencimento_range_fmt: null,
                dt_atualizacao_range_fmt: null,
                dt_cadastro_range_fmt: null,
                uploadOk: false,
                loadingUpload: false,
                desabilitaBoxEnvio: false,
                tem_irr_nao_reapresentado: null
            }
        };
    },
    computed:{
        isUsuarioAgrupador() {
            return this.$store.getters.isUsuarioAgrupador;
        },
        usuarioAgrupador() {
            return this.$store.getters.agrupadorUsuario;
        },
        agrupadores() {
            return this.$store.getters.listaAgrupadoresFiltro;
        },
    },
    methods: {
        botaoFiltrar() {
            this.filtrar = true;
            this.ultimoFiltro = this.filtros;
            this.aplicarFiltros();
        },
        aplicarFiltros() {
            if (this.revisao == true) {
                this.filtros.ds_revisao = '1';
            } else {
                this.filtros.ds_revisao = '';
            }
            if (this.oferta == true) {
                this.filtros.oferta = '1';
            } else {
                this.filtros.oferta = '';
            }
            if (this.dadosXls == null) {
                this.filtros.ds_nosso_numero = this.filtros.ds_nosso_numero;
            } else {
                this.filtros.ds_nosso_numero = this.dadosXls.toString();
            }
            this.$emit('buscarTitulos', this.filtros); 
            // this.buscarTitulos().then(data => {
            //     this.titulos = data.titulos;
            //     this.totalTitulos = data.total;
            //     if (data.total === 0) {
            //         this.mensagem = 'A Consulta não retornou nenhum titulo.';
            //         this.numero_paginas = 1;
            //     } else {
            //         this.mensagem = null;
            //         this.numero_paginas =
            //             data.total % this.registros_pagina == 0
            //                 ? data.total / this.registros_pagina
            //                 : parseInt(data.total / this.registros_pagina) + 1;
            //     }
            //     this.showPagination = this.numero_paginas > 1;
            // });
        },

        uploadFileReference(e) {
            if (!e) return null;
            this.file = e;
            this.uploadOk = false;
            const reader = new FileReader();
            var vm = this;
            reader.onload = function(e) {
                var data = e.target.result;
                if (!data) {
                    vm.dialogError = true;
                    vm.counterRender = vm.counterRender++;
                    vm.$refs.myFileInput.reset();
                    return;
                }
                var workbook;
                try {
                    workbook = XLSX.read(data, { type: 'binary' });
                } catch (err) {
                    vm.dialogError = true;
                    console.log('erro', err);
                    vm.counterRender = vm.counterRender++;
                    vm.$refs.myFileInput.reset();
                    return;
                }
                let sheetName = workbook.SheetNames[0];
                let worksheet = workbook.Sheets[sheetName];
                let rowObject = XLSX.utils.sheet_to_row_object_array(worksheet);
                const finalJsonData = JSON.stringify(rowObject, undefined, 4);
                let rows = JSON.parse(finalJsonData);
                vm.customerCountRead = rows.length;
                vm.dadosXls = rows.map(e => e.ds_nosso_numero);
            };
            reader.readAsBinaryString(this.file);
            this.fazendoUpload = true;
        },
        retornaDadosXls() {
            return this.customerCountRead;
        }
        
    },
    async mounted() {
        if (this.$store.getters.isPartner == 1) {
            this.primaryColorFont = '#757575';
        } else {
            this.primaryColorFont = this.$vuetify.theme.themes.light.primary;
        }
    }
};
</script>